.myPage {
  padding-top: 80px;
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;

  /* jy - 마이페이지 css 수정 */
  /* justify-content: center; */
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-start;
}

.myPageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  height: 100vh;

  /* jy - 마이페이지 css 수정 */
  /* justify-content: center; */
  justify-content: flex-start;
}

.myPageProfileWrap {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-bottom: 10px;
  color: #0130ab;
  border-radius: 6px;
  padding: 0 15px;
  background-color: transparent;
  /* width: 405px; */
}

.profileName {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 5px;
}

.accountManagementWrap {
  width: 405px;
  height: 200px;
}

.myPageContentTitle {
  font-size: 15px;
  font-weight: 600;
  color: #0130ab;
  margin-bottom: 10px;
  margin-top: 10px;
}

.accountManagementContent {
  border: 1.5px solid #7ca1ff;
  border-radius: 6px;
  width: 100%;
  height: 170px;
}

.accountManagementItem {
  font-size: 15px;
  color: #0130ab;
  margin: 0 0 25px 10px;
}

.accountIdWrap {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 200px;
}

.accountId {
  color: #90a3d4;
}

.myPageReviewWrap {
  width: 405px;
  height: 90px;
  margin-top: 20px;
}

.myPageReviewContent {
  border: 1.5px solid #7ca1ff;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  color: #0130ab;
}

.myPageReviewItem {
  display: flex;
  flex-direction: row;
  margin: 15px 0 0 10px;
  gap: 303px;
}

.myPageReviewIcon {
  height: 16px;
}

.myPageLogoutButton {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  color: #fffff8;
  outline: none;
  border: none;
  background-color: #7ca1ff;
  cursor: pointer;
}

.othersWrap {
  width: 405px;
  height: 98px;
  margin-top: 20px;
}

.othersContent {
  border: 1.5px solid #7ca1ff;
  border-radius: 6px;
  width: 100%;
  height: 73px;
  font-size: 15px;
  color: #0130ab;
}

.othersItem {
  font-size: 15px;
  color: #0130ab;
  margin: 10px 0 15px 10px;
}

/* jy - 리뷰 파트 css 추가 */
.userReview {
  height: 10vh;
  width: 360px;

  background-color: #e8efff;
  border-radius: 5px;
  margin: 15px;
  padding: 10px;

  font-size: 13px;
  color: #0130ab;



}

