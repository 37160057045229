.headerWrapper {
  z-index: 999999;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  width: 100%;
  border-bottom: 1.5px solid #0130ab;
  height: 80px;
  background-color: #fffff8;
}

.header {
  display: flex;
  justify-content: center;
  height: auto;
  margin-right: 1rem;
  margin-left: 1rem;
}

.headerContents {
  width: 1400px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  font-family: "ArchivoBlack-Regular", sans-serif;
  font-size: 4.4rem;
  font-weight: 900;
  letter-spacing: 2px;
  color: #0130ab;
}

.spacer {
  flex-grow: 1;
}

.userIcon {
  cursor: pointer;
}
