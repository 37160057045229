body {
  background-color: #fffff8;
}

.detailPage {
  color: #0130ab;
  padding-top: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
}

span:not(.storeDescription, .storeAddress) {
  font-weight: bold;
}

.detailPageContent
  div:not(
    .storeMap,
    .storeReviewPart,
    .modalDiv,
    .StarRateFromUsers,
    .reviewsFromUsers,
    .review
  ) {
  width: 80vw;
  border: 2px solid #7ca1ff;
  border-radius: 10px;
  margin: 15px;
}

.storeImage {
  max-height: 540px;
  height: 50%;
}

.storeImage img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.storeNameNDescription {
  height: fit-content;
  text-align: center;
}

hr {
  border: none;
  height: 2px;
  background-color: #7ca1ff;
  margin: 0;
}
.storeLocation {
  height: fit-content;
}

.storeMap {
  width: 80vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  position: relative;
}

.storeMap div {
  border: initial !important;
  border-radius: initial !important;
}

.leaveReviewBtn {
  width: 100%;
  height: 8vh;
  cursor: pointer;
  line-height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaveReviewBtn:hover {
  background-color: #7ca1ff;
  color: #fffff8;
}

.StarRateFromUsers {
  width: 80vw;
  height: 6vh;
  font-size: 30px;
  line-height: 6vh;
  margin: auto;
}

.review {
  height: 10vh;
  width: 78.5vw;

  background-color: #e8efff;
  border-radius: 5px;
  margin: 15px;
  padding: 10px;
}
.userNickname {
  padding-left: 10px;
}
.userReviewContent {
  padding-left: 10px;
}

.ReactModalPortal button {
  background-color: #fffff8;
  color: #7ca1ff;
  border: 2px solid #7ca1ff;
  border-radius: 5px;
}

.ReactModalPortal button:hover {
  background-color: #7ca1ff;
  color: #fffff8;
}

.closeModalBtn {
  display: flex;
  justify-content: right;
}

.reviewText {
  width: 40vw;
  height: 20vh;
  margin: 10px 0;
  border: 2px solid #7ca1ff;
  border-radius: 10px;

  background-color: #fffff8;
  color: #0130ab;
  outline-color: #7ca1ff;
}

.reviewText:focus {
  background-color: white;
}

@media (max-width: 500px) {
  body {
    font-size: 3vw;
  }
  .review {
    width: 77vw;
  }
  .reviewText {
    height: 10vh;
  }
}

@media (min-width: 1024px) {
  .review {
    font-size: 1vw;
  }
}
