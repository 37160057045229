.mainPage {
  padding-top: 80px;
  width: 100%;
  height: calc(100vh - 100px);
}

.speechBubbleWrap {
  max-width: 450px;
  margin: 50px auto;
}

.clear {
  clear: both;
}

.speechBubbleWrap > div.from-them,
div.me {
  max-width: 255px;
  word-wrap: break-word;
  margin-bottom: 20px;
  line-height: 24px;
}

.me {
  position: relative;
  padding: 10px 20px;
  color: white;
  background: #0130ab;
  border-radius: 25px;
  float: right;
}

.me::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -2px;
  right: -7px;
  height: 20px;
  border-right: 20px solid #0130ab;
  border-bottom-left-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
}

.me::after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -2px;
  right: -56px;
  width: 26px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
}

.from-them {
  position: relative;
  padding: 10px 20px;
  background: #e5e5ea;
  border-radius: 25px;
  color: black;
  float: left;
}

.from-them::before {
  content: "";
  position: absolute;
  z-index: 2;
  bottom: -2px;
  left: -7px;
  height: 20px;
  border-left: 20px solid #e5e5ea;
  border-bottom-right-radius: 16px 14px;
  -webkit-transform: translate(0, -2px);
}

.from-them::after {
  content: "";
  position: absolute;
  z-index: 3;
  bottom: -2px;
  left: 4px;
  width: 26px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
  -webkit-transform: translate(-30px, -2px);
}

.foodCatergoryWrap {
  max-width: 700px;
  width: 100%;
  margin: 50px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.foodCatergoryWrap > div.category {
  color: black;
  background-color: #e5e5ea;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.foodCatergoryWrap > div.category.active {
  background-color: #0130ab;
  color: white;
}

@media (max-width: 414px) {
  .foodCatergoryWrap {
    max-width: 350px;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .foodCatergoryWrap > div.category {
    margin: 8px;
    padding: 8px 16px;
  }

  .storeListWrap {
    max-width: 400px;
    margin: 30px auto;
  }
}

@media (min-width: 1024px) {
  .foodCatergoryWrap {
    max-width: 800px;
    margin: 0 auto;
  }

  .storeListWrap {
    max-width: 1000px;
    margin: 30px auto;
  }

  .foodCatergoryWrap > div.category {
    padding: 12px 24px;
    margin: 0 12px;
  }
}

.storeListWrap {
  max-width: 950px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 30px auto;
}

.storeList {
  width: 300px;
  border: 1px solid #ccc;
  color: #1e214c;
  background-color: #fffff8;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  cursor: pointer;
}

.storeList:hover {
  background-color: rgb(5, 14, 119, 0.9);
  opacitiy: 0.2;
  color: #ffffff;
}

.storeImg img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.storeName {
  font-weight: bold;
  font-size: 18px;
  padding: 11px 10px 5px 10px;
}

.storeInfo {
  padding: 10px 10px 15px 10px;
}
